import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Container from '../components/layout/Container/Container';
import Group from '../components/layout/Group/Group';
import PageLayout from '../components/layout/PageLayout/PageLayout';
import PageSection from '../components/layout/PageSection/PageSection';
import AnnouncementHeader from '../components/ui/AnnouncementHeader/AnnouncementHeader';
import Button from '../components/ui/Button/Button';
import Block from '../components/ui/Cards/Block';
import Card from '../components/ui/Cards/Card';
import Divider from '../components/ui/Divider/Divider';
import MauticForm from '../components/ui/Forms/MauticForm/MauticForm';
import HomeBanner from '../components/ui/Home/HomeBanner/HomeBanner';
import MoreArticles from '../components/ui/Home/MoreArticles/MoreArticles';
import PostsPerTopic from '../components/ui/Home/PostsPerTopic/PostsPerTopic';
import { PostsSelector } from '../components/utils/PostsSelector';
import Seo from '../components/utils/Seo';
import { useAnimationOnScroll } from '../components/utils/useAnimateOnScroll';
import { Accent, ButtonType, Direction } from '../globals';
import './index.scss';

interface HomeProps {
    data: {
        homeYaml: {
            about_section: AboutSectionBlock[];
            tabs_posts: PostsPerTopicBlock[];
            carousel_posts: string[];
            books_courses_section: BookCourse[];
        };
        allMdx: {
            nodes: PostDetails[];
        };
        topicsYaml: {
            topics: TopicDetails[];
        };
    };
}

const Home: React.FC<HomeProps> = ({ data }: HomeProps) => {
    const postsSelector = new PostsSelector(data.allMdx.nodes);
    useAnimationOnScroll();
    return (
        <PageLayout>
            <Seo isPost={false} />
            <AnnouncementHeader />
            <HomeBanner horizontalCarouselTopics={data.topicsYaml.topics} />
            <PageSection className="what-is-section">
                <Container>
                    <div className="section-text">
                        <h2>What is AI Summer</h2>
                        <p>
                            AI Summer is a free educational platform covering
                            research and applied trends in AI and Deep Learning.
                            We provide accessible and comprehensive content from
                            the entire spectrum of AI that aims to bridge the
                            gap between researchers and the public.
                        </p>
                        <p>
                            Our mission is to simplify complex concepts and
                            drive scientific research. We try to accomplish that
                            by writing highly-detailed overviews of recent deep
                            learning developments as well as thorough tutorials
                            on popular frameworks.
                        </p>
                        <p>
                            But above all, we are a community that seeks to
                            demystify the AI landscape and enable new
                            technological innovations.
                        </p>
                    </div>

                    <Group size={2}>
                        {data.homeYaml.about_section
                            .slice(0, 2)
                            .map((block, index) => {
                                return (
                                    <Block
                                        key={index}
                                        direction={Direction.vertical}
                                        aos="zoom-in"
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: block.icon
                                            }}
                                        ></span>
                                        <h3>{block.title}</h3>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: block.desc
                                            }}
                                        ></div>
                                    </Block>
                                );
                            })}
                    </Group>
                    <Group size={2}>
                        {data.homeYaml.about_section
                            .slice(2, 4)
                            .map((block, index) => {
                                return (
                                    <Block
                                        key={index}
                                        direction={Direction.vertical}
                                        aos="zoom-in"
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: block.icon
                                            }}
                                        ></span>
                                        <h3>{block.title}</h3>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: block.desc
                                            }}
                                        ></div>
                                    </Block>
                                );
                            })}
                    </Group>
                </Container>
            </PageSection>
            <PageSection className="posts-per-topic-section">
                <Container>
                    <div className="section-text">
                        <h2>Explore our content</h2>
                        <p>
                            An Artificial Intelligenge hub where you can find
                            and learn anything related to Deep Learning. From
                            fundamental principles to state of art research and
                            real-life applications
                        </p>
                    </div>

                    <PostsPerTopic
                        {...postsSelector.getByTopic(data.homeYaml.tabs_posts)}
                    />

                    <Button
                        accent={Accent.secondary}
                        type={ButtonType.linkInternal}
                        onClickLink="/learn-ai/"
                        className="see-all-button"
                    >
                        See all articles
                    </Button>
                </Container>
            </PageSection>

            <PageSection className="newsletter-section">
                <Container>
                    <div className="section-text section-text--light">
                        <h2>Join the AI Summer community</h2>
                        <h3>
                            Subscribe to our newsletter and get access to all of
                            our free content and resources into your inbox
                        </h3>
                    </div>
                    <MauticForm
                        formId="1"
                        formName="registrationfrompost"
                        isDark={true}
                    />
                </Container>
            </PageSection>
            {/* <PageSection className="topics-section">
                <Container>
                    <div className="section-text">
                        <h2>Learn how Machine learning works</h2>
                        <p>
                            Start from the basics and learn about architectures
                            such as Convolutional Networks and LSTMs.
                        </p>
                        <p>
                            Continue with more advance concepts like
                            Reinfocement and Generative Learning by diving into
                            state of the art research papers.
                        </p>
                        <p>
                            Finally it’s time to put your skills into practice
                            by developing Computer Vision and Natural Language
                            Processing applications using the most popular
                            frameworks.
                        </p>
                    </div>
                    <Topics topics={data.topicsYaml.topics} />
                </Container>
                
            </PageSection> */}
            <PageSection className="books-courses-section">
                <Container>
                    <div className="section-text">
                        <h2>Explore our courses and books</h2>
                        <p>
                            Although 99% of our content is available for free,
                            we do offer some paid courses and books. Why?
                        </p>
                        <p>
                            Because we need a way to cover hosting and other
                            expenses. So you can consider buying them just to
                            support our work.
                        </p>
                        <p>
                            However, we invest even more effort into our paid
                            content in order to keep the quality as high as
                            possible. Towards that goal, we try to a) maximize
                            flow between concepts, b) minimize the external
                            links and c) update them as frequently as possible.
                        </p>
                    </div>
                    <Card
                        direction={Direction.horizontal}
                        className="books-courses-card"
                        aos="zoom-in"
                        onClickLink={data.homeYaml.books_courses_section[0].url.substring(
                            1
                        )}
                    >
                        <div className="books-courses-card__details">
                            <h3>
                                {data.homeYaml.books_courses_section[0].title}{' '}
                            </h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        data.homeYaml.books_courses_section[0]
                                            .description || ''
                                }}
                            ></div>
                            <Link
                                to={data.homeYaml.books_courses_section[0].url}
                            >
                                Learn more
                            </Link>
                        </div>
                        <div className="books-courses-card__image">
                            <Img
                                fluid={
                                    data.homeYaml.books_courses_section[0].image
                                        .childImageSharp.fluid
                                }
                                alt={
                                    data.homeYaml.books_courses_section[0].title
                                }
                            ></Img>
                        </div>
                    </Card>
                    <Card
                        direction={Direction.horizontal}
                        className="books-courses-card"
                        aos="zoom-in"
                        onClickLink={data.homeYaml.books_courses_section[1].url.substring(
                            1
                        )}
                    >
                        <div className="books-courses-card__image--course-type ">
                            <Img
                                fluid={
                                    data.homeYaml.books_courses_section[1].image
                                        .childImageSharp.fluid
                                }
                                alt={
                                    data.homeYaml.books_courses_section[1].title
                                }
                            ></Img>
                        </div>
                        <div className="books-courses-card__details">
                            <h3>
                                {data.homeYaml.books_courses_section[1].title}{' '}
                            </h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        data.homeYaml.books_courses_section[1]
                                            .description || ''
                                }}
                            ></div>
                            <Link
                                to={data.homeYaml.books_courses_section[1].url}
                            >
                                Learn more
                            </Link>
                        </div>
                    </Card>
                </Container>
            </PageSection>
            <PageSection className="newsletter-section">
                <Container>
                    <div className="section-text section-text--light">
                        <h2>Do you want to contribute to our platform?</h2>
                        <h3>
                            Join us on our Discord server and let's have a chat
                            about future article or open source contributions.
                        </h3>
                        <h3>
                            You might also meet like-minded people and even
                            start a project together.
                        </h3>
                        <Button
                            accent={Accent.primary}
                            type={ButtonType.linkExternal}
                            onClickLink="https://discord.gg/2ezWq3r5hv"
                            className="discord-button"
                        >
                            Join us on Discord
                        </Button>
                    </div>
                </Container>
            </PageSection>
            <Divider direction={Direction.horizontal} />
            <PageSection className="more-articles-section">
                <Container>
                    <div className="section-text">
                        <h2>More articles</h2>
                    </div>
                    <MoreArticles
                        posts={data.allMdx.nodes.map(
                            (node) => node.frontmatter
                        )}
                    />
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const homeQuery = graphql`
    {
        homeYaml {
            tabs_posts {
                posts
                topic
            }
            carousel_posts
            about_section {
                desc
                icon
                title
            }
            books_courses_section {
                title
                description
                url
                image {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
            }
        }
        allMdx(
            filter: { frontmatter: { layout: { eq: "BlogPage" } } }
            sort: { fields: frontmatter___publishedAt, order: DESC }
        ) {
            nodes {
                frontmatter {
                    author
                    description
                    image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    layout
                    suburl
                    tags
                    title
                }
            }
        }
        topicsYaml {
            topics {
                desc
                title
                url
                logo
            }
        }
    }
`;

export default Home;
