import { Link } from 'gatsby';
import React from 'react';
import { Accent, ButtonType } from '../../../../globals';
import Button from '../../Button/Button';
import HorizontalCarousel from '../../Carousel/HorizontalCarousel';
import './HomeBanner.scss';

interface HomeBannerProps {
    // carouselPosts: Record<string, Frontmatter>;
    horizontalCarouselTopics: TopicDetails[];
}

const HomeBanner: React.FC<HomeBannerProps> = (props: HomeBannerProps) => {
    return (
        <div className="home-banner">
            <div className="home-banner__content">
                <div className="home-banner__content__details">
                    <h1> Learn Deep Learning and Artificial Intelligence</h1>

                    <p>
                            Discover the world of Deep Learning with comprehensive
                            overviews and tutorials on the latest research. Join our
                            community and help us demystify the AI landscape.
                    </p>

                    <Button
                        accent={Accent.primary}
                        type={ButtonType.linkInternal}
                        onClickLink="/start-here/"
                        className="home-banner__content__details__cta"
                    >
                        Get started
                    </Button>
                </div>
                <div className="home-banner__content__image">
                    {/* <Card direction={Direction.vertical}>
                        <Carousel slidesNum={3}>
                            {Object.entries(props.carouselPosts).map((p) => (
                                <Img key={p[0]} fluid={p[1].image.childImageSharp.fluid}></Img>
                            ))}
                        </Carousel>
                    </Card> */}
                    {/* <ParallelepipedSpinner>
                        {Object.entries(props.carouselPosts).map((p) => (
                            <Img
                                key={p[0]}
                                fluid={p[1].image.childImageSharp.fluid}
                            ></Img>
                        ))}
                    </ParallelepipedSpinner> */}
                    {/* <Card direction={Direction.vertical}>
                            <img src={gif}></img>
                    </Card> */}
                    <HorizontalCarousel
                        slidesNum={props.horizontalCarouselTopics.length}
                    >
                        {[...props.horizontalCarouselTopics]
                            .sort()
                            .sort(() => Math.random() - 0.5)
                            .map((topic, i) => (
                                <Link
                                    key={topic.title}
                                    className="carousel-topic"
                                    to={`/topics/${topic.url}`}
                                >
                                    <div className="carousel-topic__logo-wrapper">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: topic.logo || ''
                                            }}
                                        ></span>
                                    </div>
                                    <div className="carousel-topic__title">
                                        <h4>{topic.title}</h4>
                                    </div>
                                </Link>
                            ))}
                    </HorizontalCarousel>
                </div>
            </div>
            <div className="home-banner__background-wave"></div>
        </div>
    );
};

export default HomeBanner;
